import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IShopData {
    "id"?: string,
    "name"?: string,
    "email"?: string,
    "country"?: string,
    "shopOwnerName"?: string
}

export interface ClientState {
  name: string;
  email: string;
  description: string;
  showOutOfStock: boolean;
  autoSync: boolean;
  syncIntervalSeconds: number;
  isLooksAvailable: boolean;
  looksTag: string;
  platformId: string;
  headers: { [key: string]: string };
  storeUrl: string;
  storeAdminUrl: string;
  globalTag: string;
  shopDetails: IShopData
}

const initialState: ClientState = {
  name: '',
  email: '',
  description: '',
  showOutOfStock: false,
  autoSync: false,
  syncIntervalSeconds: 43200,
  isLooksAvailable: false,
  looksTag: '',
  platformId: '',
  headers: {},
  storeUrl: '',
  storeAdminUrl: '',
  globalTag: '',
  shopDetails: {}
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    updateClient: (state, action: PayloadAction<Partial<ClientState>>) => {
      return { ...state, ...action.payload };
    },
    updateHeader: (
      state,
      action: PayloadAction<{ headerKey: string; value: string }>,
    ) => {
      const { headerKey, value } = action.payload;
      state.headers[headerKey] = value;
    },
    setShopDetails(state,action){
      state.shopDetails = action.payload
    }
  },
});

export const { updateClient, updateHeader,setShopDetails } = clientSlice.actions;

export default clientSlice.reducer;
