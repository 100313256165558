import { createSlice } from '@reduxjs/toolkit';

export interface IAnalyticsEventProps{
    daysdiff: number
    event: string
    userType: string
    eventType: 'basic' | 'jql' | 'jqldate'
    groupBy?:string
    filterProps?: {
      reduceAs: 'count' | 'sum',
      getTop?: number
      reduceProperty?: string
  }
}

export interface IAnalyticsRenderedData {
  id: number
  name: string
  data: any
}

export interface IFetchData{
    data: any
    eventProps: IAnalyticsEventProps
}

export interface AnalyticsState {
    analyticsfetchedData: IFetchData[]
    analyticsData:  IAnalyticsRenderedData[]
}

const initialState: AnalyticsState = {
  analyticsfetchedData: [],
  analyticsData: []
};

const analyticsSlice = createSlice({
  name: 'Analytics',
  initialState,
  reducers: {
    pushAnalyticsFetchedData(state,action){
        const newdata = action.payload
        const currData = JSON.parse(JSON.stringify(state.analyticsfetchedData))
        state.analyticsfetchedData = [...currData,newdata]
    },
    pushAnalyticsRenderedData(state,action) {
      const newdata = action.payload
      const currData = JSON.parse(JSON.stringify(state.analyticsData))
      state.analyticsData = [...currData,newdata]
    }
  },
});

export const {  pushAnalyticsFetchedData,pushAnalyticsRenderedData} = analyticsSlice.actions;

export default analyticsSlice.reducer;
