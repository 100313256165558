export const AnalyticsIds = {
    getStartedCliked: 'Get Started Clicked',
    themeSelected: 'Theme Selected',
    vtoButtonInstalled: 'VTO Button Installed',
    vtoFabInstalled: 'VTO FAB Installed',
    stage1Completed: 'Stage 1 Completed',
    stage2Completed: 'Stage 2 Completed',
    chooseProductClicked: 'Choose Product Clicked',
    productAdded: 'Product Added',
    configuratorFinished: 'Configurator Finished',
    vtoHomeViewed: 'VTO Home Viewed',
    upgradePlanClicked: 'Upgrade Plan Clicked',
    vtoProductIntegrationViewed: 'VTO Product Integration Viewed',
    vtoConfigurationViewed: 'VTO Configuration Viewed',
    vtoAnalyticsViewed: 'VTO Analytics Viewed',
    vtoFaqViewed: 'VTO FAQ Viewed',
    vtoChoosePlanViewed: 'VTO Choose Plan Viewed',
    cancelPlanClicked: 'Cancel Plan Clicked',
    choosePlanClicked: 'Choose Plan Clicked'
}