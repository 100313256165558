import { useState } from 'react';
import { CaretDownIcon, CaretUpIcon } from '@shopify/polaris-icons';
import classes from './faq.module.scss'
import {
    Button, Text, Divider, Card, Icon, List
} from '@shopify/polaris';
import { allFaqs } from '../utils/constants';

export default function FAQ() {
    const [faqs, setFaqs] = useState(allFaqs)
    return <div className={classes.container}>
        <Text as='h3' fontWeight='bold'>FAQ</Text>
        <div className={classes.faqContainer}>
            <div className={classes.section1}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <Text as='h6' fontWeight="bold">Frequently Asked Question</Text>
                    <Text variant="bodySm" as='p'>THere are our most frequently asked questions. For more details about Metadome's Virtual Try-On or if your question isn’t covered, please contact us - gaurav@metadome.ai</Text>
                </div>
            </div>
            <div className={classes.section2}>
                <Card>
                    <div className={classes.faqs}>
                        {faqs.map((faq, index) =>
                            <div key={faq.question} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => {
                                    const temp = [...faqs]
                                    temp[index].open = !temp[index].open
                                    setFaqs(temp)
                                }}>
                                    <Button fullWidth={true} size="large" textAlign='left' variant="tertiary">
                                        {faq.question}
                                    </Button>
                                    <Icon source={!faq.open ? CaretDownIcon : CaretUpIcon} tone="base" />
                                </div>
                                {faq.open &&
                                    faq.type === 'list' ?
                                    <List type='number' >
                                        {faq.answers?.map((point: string) => <List.Item>
                                            <Text as='p' variant='bodySm' fontWeight='bold'>{point.split(':')[0]}</Text>
                                            <Text as='p' variant='bodySm' >{point.split(':')[1]}</Text>
                                        </List.Item>)}
                                    </List>
                                    :
                                    <div style={{ paddingLeft: '15px' }}>
                                        <Text as='p' variant="bodyMd">{faq.answer}</Text>
                                    </div>
                                }
                                <Divider borderColor="border-inverse" />
                            </div>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    </div>
}