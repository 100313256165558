import { IAnalyticsData, IOperation } from "../components/Analytics/Analytics";
import { getShop } from "../components/GlobalVariables";
import { IAnalyticsEventProps, IFetchData, pushAnalyticsFetchedData } from "../store/slices/analyticsSlice";
import store from "../store/store";
import { linkType } from "./mixpanel";

export interface ICardState{
    id?: number;
    loading: boolean;
    data: any | any[];
    error: string;
}

export const analyticsData: IAnalyticsData[][] = [[
    {
        id: 0,
        name: 'Monthly Active Users (MAU)',
        filter: '',
        graphType: 'number',
        eventProps: {
            daysdiff: 30,
            event: 'Session Time',
            eventType: 'jqldate',
            userType: 'unique',
            groupBy: ""
        }
    },
    {
        id: 1,
        name: 'Weekly Active Users (WAU)',
        filter: '',
        graphType: 'number',
        eventProps: {
            daysdiff: 7,
            event: 'Session Time',
            eventType: 'jqldate',
            userType: 'unique',
            groupBy: ""
        }
    },
    {
        id: 2,
        name: 'Daily Active Users (DAU)',
        filter: '',
        graphType: 'number',
        eventProps: {
            daysdiff: 0,
            event: 'Session Time',
            eventType: 'jqldate',
            userType: 'unique',
            groupBy: ""
        }
    }],[
    {
        id: 3,
        name: 'Total VTO Sessions',
        filter: '',
        graphType: 'number',
        eventProps: {
            daysdiff: 200,
            event: 'Session Time',
            eventType: 'jqldate',
            userType: 'general',
            groupBy: ""
        }
    },
    {
        id: 4,
        name: 'Clicks on the VTO button',
        filter: '',
        graphType: 'number',
        eventProps: {
            daysdiff: 500,
            event: 'VTO Button Clicked',
            eventType: 'jqldate',
            userType: 'general',
            groupBy: ""
        }
    },
    {
        id: 5,
        name: 'User engagement time(sec)',
        filter: '',
        operation: {
            withIndex: 3,
            operation : ';/^'
        },
        graphType: 'number',
        eventProps: {
            daysdiff: 500,
            event: 'Session Time',
            eventType: 'jql',
            userType: 'general',
            groupBy: "properties.StoreName",
            filterProps: {
                reduceAs: 'sum',
                reduceProperty: 'properties.$duration'
            }
        }
    }],[
    {
        id: 6,
        name: 'Top-performing categories',
        filter: '',
        graphType: 'barchart',
        eventProps: {
            daysdiff: 30,
            event: 'Session Time',
            eventType: 'jql',
            userType: 'general',
            groupBy: "properties.Category",
            filterProps: {
                reduceAs: 'count',
                getTop: 5
            }
        }
    }, {
        id: 7,
        name: 'Categories breakdown',
        filter: '',
        graphType: 'donutchart',
        eventProps: {
            daysdiff: 30,
            event: 'Session Time',
            eventType: 'jql',
            userType: 'general',
            groupBy: "properties.Category",
            filterProps: {
                reduceAs: 'count'
            }
        }
    }],
    [
        {
            id: 8,
            name: 'Top-performing products',
            filter: '',
            graphType: 'barchart',
            eventProps: {
                daysdiff: 30,
                event: 'Session Time',
                eventType: 'jql',
                userType: 'general',
                groupBy: "properties.ProductName",
                filterProps: {
                    reduceAs: 'count',
                    getTop: 5
                }
            }
        }
    ]
]


const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const currentDate = new Date()
const getPastDate = (daysdiff:number) => {
    const pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - daysdiff)
    return pastDate
}

const getMatchDataFromStore = (eventProps:IAnalyticsEventProps) => {
    const analyticsfetchedData = store.getState().analytics.analyticsfetchedData
    const dataAvailable = analyticsfetchedData.find((ob) => ob.eventProps.event === eventProps.event && ob.eventProps.eventType === eventProps.eventType && ob.eventProps.userType === eventProps.userType)
    console.log("operation1",dataAvailable) 
    return dataAvailable

}

const performOperations = (op:IOperation,targetVal:number) => {
    let operation = op.operation
        for(const y of analyticsData){
            for(const x of y){
                if(x.id === op.withIndex){
                    console.log("operation2",x) 
                    const analyticsData = store.getState().analytics.analyticsData
                    const filteredData = analyticsData.find((ob) => ob.id === x.id)?.data
                    operation = operation.replace(";", String(targetVal))
                    operation = operation.replace("^", String(filteredData))
                    console.log("operation3",operation) 
                }
            }
        }
    console.log("operation3",operation,eval(operation))    
    return Math.ceil(eval(operation))    

}

const  mergeWithZeroCounts = (allDates:string[], eventData:any) =>  {  
    let eventCounts:any = {};  
    console.log("res2",eventData,allDates)

    // Convert eventData array to a dictionary
    eventData.forEach((item:any) => {
        const [day, month, year] = item.key[0].split("-");
        const date = `${year}-${month}-${day}`
        eventCounts[date] = item.value; // key[0] contains formatted date
    });

    // Ensure all dates exist in final output
    return allDates.map(date => ({  
        key: [date],  
        value: eventCounts[date] || 0  // Set to 0 if date is missing
    }));  
}

const generateDateRange = (start: Date, end:Date) => {  
    let dates = [];  
    let current = new Date(start);  
    while (current <= end) {  
        dates.push(formatDate(current));  
        current.setDate(current.getDate() + 1);  
    }  
    return dates;  
}

export const getAnalytics = (analytics:IAnalyticsData): Promise<ICardState> => {
    const {eventType,daysdiff,event,userType,filterProps,groupBy} = analytics.eventProps
    const getTop = filterProps?.getTop
    const reduceAs = filterProps?.reduceAs
    const reduceProperty = filterProps?.reduceProperty
    const operation = analytics?.operation
    console.log("operation0",operation)
    const currentFormattedDate = formatDate(currentDate);
    const pastFormattedDate = formatDate(getPastDate(daysdiff));
    // console.log("dates",currentFormattedDate,pastFormattedDate,daysdiff)
    const analyticsfetchedData = store.getState().analytics.analyticsfetchedData
    if(eventType === 'basic'){
        const dataAvailable = analyticsfetchedData.find((ob) => ob.eventProps.event === event && ob.eventProps.eventType === eventType && ob.eventProps.userType === userType)
        // console.log("dataAvailable",dataAvailable,daysdiff)
        if(dataAvailable){
            let dataArr = Object.values(dataAvailable?.data ?? {})
            let sum = 0
            const lastIndex = dataArr.length - 1
            dataArr = dataArr.slice(lastIndex - daysdiff,dataArr.length)
            // console.log("dataSlice",dataArr,lastIndex - daysdiff,lastIndex,daysdiff)
            return new Promise((resolve) => {
                dataArr.forEach((element:any) => {
                    sum += element.value
                });
                if(operation){
                    sum = performOperations(operation,sum)
                }
                resolve({'data': sum,loading:false,error: ""})
            })
        }
        const queryParams = `project_id=${encodeURIComponent(2846568)}&event=${encodeURIComponent(event)}&name=${encodeURIComponent('product')}&values=${encodeURIComponent(JSON.stringify(["VTO-shopify"]))}&type=${userType ?? 'general'}&unit=day&from_date=${encodeURIComponent(pastFormattedDate)}&to_date=${encodeURIComponent(currentFormattedDate)}`
        return new Promise(async(resolve) => {
            try{
                const options = {
                    method: 'GET',
                    headers: {
                      accept: 'application/json',
                      authorization: 'Basic dnRvLXNob3BpZnkuMWZiZGQyLm1wLXNlcnZpY2UtYWNjb3VudDpSSGNZcUdod2ZhTEN2RU1JYndiOWl3RTRIZDVxM25IZw=='
                    }
                  };
                await fetch(`https://mixpanel.com/api/query/events/properties?${queryParams}`, options)
                .then(res => res.json())
                .then(res => {
                    const temp = res.data.values?.['VTO-shopify']
                    const arr =  Object.keys(temp)
                    const timeMapping:{[key:number]:any} = {}
                    for (const x of arr){
                        const time = new Date(x).getTime()
                        timeMapping[time] = {
                            value: temp[x],
                            date: x
                        }
                    }
                    const dataArr = Object.values(timeMapping ?? {})
                    let sortedObj:{[key:number]:any} = {}
                    const sortedJson = Object.keys(timeMapping).map(Number).sort((a, b) => a - b);  // Sort the keys
                    sortedJson.forEach((ob) => {
                        sortedObj[ob] = timeMapping[ob]
                    })
                    const fetchData: IFetchData = {
                        data: sortedObj,
                        eventProps: {
                            daysdiff,
                            event,
                            userType,
                            eventType
                        }  
                    }
                    console.log("sorted",sortedJson,sortedObj)    
                    store.dispatch(pushAnalyticsFetchedData(fetchData))
                    let sum = 0
                    dataArr.forEach((element:any) => {
                        sum += element.value
                    });
                    if(operation){
                        sum = performOperations(operation,sum)
                    }
                    resolve({'data': sum,loading:false,error: ""})
                })
            }
            catch{
                resolve({'data': null,loading:false,error: "Error occured fetching data"})
            }
        }) 
    } else if(eventType === 'jql'){
        const dataAvailable = analyticsfetchedData.find((ob) => ob.eventProps.event === event && ob.eventProps.eventType === eventType && ob.eventProps.userType === userType && ob.eventProps.groupBy === groupBy)
        if(dataAvailable){
            let dataArr = Object.values(dataAvailable?.data ?? {})
            if(getTop){
                const lastIndex = Number(getTop ?? 0)
                dataArr = dataArr.slice(0,lastIndex)
            }
            return new Promise((resolve) => {
                resolve({'data': dataArr,loading:false,error: ""})
            })
        }
        return new Promise(async(resolve) => {
            try{
                const encodedParams = new URLSearchParams();
                encodedParams.set('script', `function main() { return Events({ from_date: params.start_date, to_date: params.end_date, }).filter(function(event) { return event.name == params.event && event.properties.product === "VTO-shopify" && event.properties.fromLink === "${linkType}" && event.properties.StoreName === "${getShop()}" }).groupBy(["${groupBy}"], ${reduceAs === 'count' ? 'mixpanel.reducer.count()': `mixpanel.reducer.sum("${reduceProperty}")`})}`);
                encodedParams.set('params', JSON.stringify({
                "start_date": `${pastFormattedDate}`,
                "end_date": `${currentFormattedDate}`,
                "event": `${event}`
                }))
                const url = 'https://mixpanel.com/api/query/jql?project_id=2846568';
                const options = {
                  method: 'POST',
                  headers: {
                    accept: 'application/json',
                    'content-type': 'application/x-www-form-urlencoded',
                    authorization: 'Basic dnRvLXNob3BpZnkuMWZiZGQyLm1wLXNlcnZpY2UtYWNjb3VudDpSSGNZcUdod2ZhTEN2RU1JYndiOWl3RTRIZDVxM25IZw=='
                  },
                  body: encodedParams
                };
                await fetch(url, options)
                .then(res => res.json())
                .then(res => {
                    let data:any = []
                    // console.log("res",res)
                    res.forEach((obj:any) => {
                        const name = obj?.key[0]
                        const value = obj?.value
                        if(name !== ''){
                            data.push({
                                name: name,
                                data: [{
                                    key: name,
                                    value: value
                                }]
                            })
                        }
                    });
                    data.sort((a:any, b:any) => b.data[0].value - a.data[0].value);
                    const fetchData: IFetchData = {
                        data: data,
                        eventProps: {
                            daysdiff,
                            event,
                            userType,
                            eventType,
                            groupBy,
                            filterProps
                        }  
                    }
                    store.dispatch(pushAnalyticsFetchedData(fetchData))
                    if(getTop){
                        const lastIndex = Number(getTop ?? 0)
                        data = data.slice(0,lastIndex)
                    }
                    if(reduceAs === 'sum'){
                        data = data.find((ob:any) => ob.name === getShop()).data[0].value
                        data = Math.round(data)
                        if(operation){
                            data = performOperations(operation,data)
                        }
                    }
                    // console.log("res",data)
                    resolve({'data': data,loading:false,error: ""})
                })
            }
            catch{
                resolve({'data': null,loading:false,error: "Error occured fetching data"})
            }
        }) 
    } else if(eventType === 'jqldate'){
        const dataAvailable = analyticsfetchedData.find((ob) => ob.eventProps.event === event && ob.eventProps.eventType === eventType && ob.eventProps.userType === userType && ob.eventProps.groupBy === groupBy)
        if(dataAvailable){
            let dataArr = Object.values(dataAvailable?.data ?? {})
            let sum = 0
            const lastIndex = dataArr.length - 1
            dataArr = dataArr.slice(lastIndex - daysdiff,dataArr.length)
            console.log("dataSlice",dataArr,lastIndex - daysdiff,lastIndex,daysdiff)
            return new Promise((resolve) => {
                dataArr.forEach((element:any) => {
                    sum += element.value
                });
                if(operation){
                    sum = performOperations(operation,sum)
                }
                resolve({'data': sum,loading:false,error: ""})
            })
        }
        return new Promise(async(resolve) => {
            try{
                const encodedParams = new URLSearchParams();
                encodedParams.set('script', `function main() {      return Events({         from_date: params.start_date,          to_date: params.end_date     })     .filter(function(event) {          return event.name === params.event              && event.properties.product === "VTO-shopify"              && event.properties.fromLink === "${linkType}" && event.properties.StoreName === "${getShop()}";      })     .map(function(event) {       return {             ...event,             formatted_time: formatDate(event.time)         };     })  .groupBy(         ["formatted_time"],         mixpanel.reducer.count()     )}  function formatDate(timestamp) {     const date = new Date(timestamp);      const day = String(date.getDate()).padStart(2, "0");      const month = String(date.getMonth() + 1).padStart(2, "0");      const year = date.getFullYear();     return\`\${day}-\${month}-\${year}\`; }`);
                encodedParams.set('params', JSON.stringify({
                "start_date": `${pastFormattedDate}`,
                "end_date": `${currentFormattedDate}`,
                "event": `${event}`
                }))
                const url = 'https://mixpanel.com/api/query/jql?project_id=2846568';
                const options = {
                  method: 'POST',
                  headers: {
                    accept: 'application/json',
                    'content-type': 'application/x-www-form-urlencoded',
                    authorization: 'Basic dnRvLXNob3BpZnkuMWZiZGQyLm1wLXNlcnZpY2UtYWNjb3VudDpSSGNZcUdod2ZhTEN2RU1JYndiOWl3RTRIZDVxM25IZw=='
                  },
                  body: encodedParams
                };
                await fetch(url, options)
                .then(res => res.json())
                .then(res => {
                    let data:any = []
                    console.log("res",res)
                    const allDates = generateDateRange(getPastDate(daysdiff),new Date())
                    let x = mergeWithZeroCounts(allDates, res);
                    // res.forEach((obj:any) => {
                    //     const name = obj?.key[0]
                    //     const value = obj?.value
                    //     if(name !== ''){
                    //         data.push({
                    //             name: name,
                    //             data: [{
                    //                 key: name,
                    //                 value: value
                    //             }]
                    //         })
                    //     }
                    // });
                    // data.sort((a:any, b:any) => b.data[0].value - a.data[0].value);
                    console.log("res1",data,x,)
                    const fetchData: IFetchData = {
                        data: x,
                        eventProps: {
                            daysdiff,
                            event,
                            userType,
                            eventType,
                            groupBy,
                            filterProps
                        }  
                    }
                    store.dispatch(pushAnalyticsFetchedData(fetchData))
                    // if(reduceAs === 'sum'){
                    //     data = data.find((ob:any) => ob.name === getShop()).data[0].value
                    //     data = Math.round(data)
                    //     if(operation){
                    //         data = performOperations(operation,data)
                    //     }
                    // }
                    // console.log("res",data)
                    let sum = 0
                    const lastIndex = x.length - 1
                    x = x.slice(lastIndex - daysdiff,x.length)
                    // console.log("dataSlice",x,lastIndex - daysdiff,lastIndex,daysdiff)
                    x.forEach((element:any) => {
                        sum += element.value
                    });
                    if(operation){
                        sum = performOperations(operation,sum)
                    }
                    resolve({'data': sum,loading:false,error: ""})
                })
            }
            catch{
                resolve({'data': null,loading:false,error: "Error occured fetching data"})
            }
        }) 
    }
    return new Promise((resolve) => {resolve({data: '',id:0,loading: false,error: "Data cannot be fetched"})})
}