import {
    logEvent as logEventCore,
    EventType,
    IEvent,
    initAnalytics
  } from '@adloid-technologies/analyticssdk-web'
import { getShop } from '../components/GlobalVariables'

export const linkType = process.env.REACT_APP_LINKTYPE ?? ''

export const logEvent = (type: EventType, event: IEvent) => {
    loggingEvents(type, event)
}

export const loggingEvents = (type: EventType, event: IEvent) => {
  const segmentation = event?.segmentation ?? {}
    const updatedEvent = {
      ...event,
      segmentation: {
        linkId: process.env.linkId as string,
        fromLink: linkType,
        StoreName: getShop(),
        ...segmentation
      }
    }
    logEventCore(type, updatedEvent)
}  

// export const registermixpanel = () => {
//     registerMixpanel('vto-dashboard', linkType || '', 'VTO-Dashboard')
// }
  
export const initLog = () => {
    initAnalytics('metadome-vtoDashboard-web', 'vto-dashboard', 'webgl', linkType,'VTO-Dashboard', {
      log: !['main', 'staging'].includes(linkType || '')
    })
    // registermixpanel()
    // logEvent('add_event', {
    //   key: AnalyticsIds.initMixpanel,
    //   segmentation: {
    //     formFactor: '3D',
    //     fromLink: linkType,
    //     product: 'vto-shopify'
    //   }
    // })
  }