import { Form, FormLayout, Text, TextField, Button, Frame, Toast, Popover } from '@shopify/polaris';
import axios from 'axios';
import { useCallback, useState } from 'react';
import classes from './Contactus.module.scss'
import store from '../store/store';
import { contactUsFormDropDownData } from '../utils/constants';

export const ContactUs = () => {
  const shopDetails = store.getState().client.shopDetails
  const [firstname, setFirstName] = useState(shopDetails?.shopOwnerName ?? '')
  const [email, setEmail] = useState(shopDetails?.email ?? '')
  const [description, setDescription] = useState('')
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [showSubmitted, setShowSubmitted] = useState(false)
  const [showError, setShowError] = useState(false)
  const [files, setFiles] = useState<File[]>([]);

  const handleSubmit = () => {

    if(!firstname || !email || selectedQuestion === ""){
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 6000);
      return
    }

    const formData = new FormData()
    formData.append('FromAddress','noreply@metadome.ai')
    formData.append("toAddress", "mohana@metadome.ai")
    formData.append('FromName', "Metadome")
    formData.append("plainText", " ")
    formData.append("Recipients", JSON.stringify([
      "info@metadome.ai"
    ]))
    formData.append("Subject", "Query: from Shopify")
    formData.append("HTML", ` <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Email Template</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #f7f7f7;
        }
        .email-container {
          max-width: 600px;
          margin: 0 auto;
          background-color: white;
          padding: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .header {
          background-color: #4caf50;
          color: white;
          padding: 20px;
          text-align: center;
        }
        .content {
          padding: 20px;
          color: #333;
        }
        .button {
          display: inline-block;
          padding: 10px 20px;
          background-color: #4caf50;
          color: white;
          text-decoration: none;
          margin-top: 10px;
        }
        .footer {
          text-align: center;
          color: white;
          background-color: #333;
          padding: 20px;
          margin-top: 20px;
        }
      </style>
    </head>
    <body>
      <div class="email-container">
        <div class="header">
          <h1>Welcome</h1>
        </div>
  
        <div class="content">
          <p>Hello there!</p>
          <p>
            Name: ${firstname}
            <br />
            Email: ${email}
            <br />
            Question: ${selectedQuestion} 
            <br />
            Query: ${description} 
          </p>
        </div>
  
        <div class="footer">
          <p>&copy; Meadome.ai, All Rights Reserved.</p>
        </div>
      </div>
    </body>
  </html>`)
  formData.append('attachments',files[0])
  
    const config = {
      method: 'post',
      url: `https://api.metadome.ai/commn/email/send-email`,
      headers: {
        'Content-Type': 'application/json'
      },
      CC: [""],
      formData: formData
    }

    axios.request(config).then(() => {
      setShowSubmitted(true)
      setFirstName('')
      setEmail('')
      setSelectedQuestion('')
      setDescription('')
      setTimeout(() => {
        setShowSubmitted(false)
      }, 8000);
    })
  }

  const options = contactUsFormDropDownData

  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const activator = (
    <button onClick={(e) => {e.preventDefault(); togglePopoverActive()}} className={classes.dropdownbtn}>
      {selectedQuestion === "" ? 'Select a question' : selectedQuestion}
    </button>
  );

  const NotifyError = showError ? (
    <Toast content={`Please enter all the details to submit.`} onDismiss={() => { setShowError(false) }} />
  ) : null;

  const NotifySubmit = showSubmitted ? (
    <Toast content={`Form submitted! We will get in touch with you soon.`} onDismiss={() => { setShowSubmitted(false) }} />
  ) : null;
  return (
    <Frame>
      {NotifyError}
      {NotifySubmit}
      <div style={{ display: 'flex', flexDirection: 'column', gap: "15px" }} className={classes.wrapper}>
        <Text as='h4' fontWeight='bold'>Get in touch with us</Text>
        <Form onSubmit={() => {
          handleSubmit()
        }}>
          <FormLayout>
            <div style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
              justifyContent: 'space-between'
            }}>
            <div style={{ width: '100%' }}>
              <TextField
                value={firstname}
                onChange={setFirstName}
                label="Name"
                autoComplete='off'
                type="text"
              />
            </div>
            <div style={{ width: '100%' }}>
              <TextField
                value={email}
                onChange={setEmail}
                label="Email"
                type="email"
                autoComplete="email"
                helpText={
                  <span>
                    We’ll use this email address to inform you on future changes.
                  </span>
                }
              />
            </div>
            </div>
            <div style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
              justifyContent: 'space-between'
            }} className={classes.dropdownwrapper}>
            <div
              style={{
                display: "flex",
                flexDirection: 'column',
                gap: '10px',
                alignItems: "flex-start",
                fontSize: '0.8rem',
                width: '100%'
              }}
              className={classes.temp}
            >
              <span>Select your Issue</span>
              <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
                preferredPosition="below"
                sectioned
                fullWidth={true}
              >
                <div
                id="elems"
                      className={classes.elems}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: '200px',
                        overflow: 'scroll',
                      }}
                    >
     
                      {options.map((ob) => {
                        return (
                          <div className={classes.elewrapper} style={{
                            width:'100%',
                            padding:'10px 20px',
                            background: ob?.value === selectedQuestion ? 'rgba(247, 247, 247, 1)': '',
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            setSelectedQuestion(ob.value)
                            togglePopoverActive()
                            }}>
                            {/* <img src="./assets/icons/tick.svg"/> */}
                             <span
                            className={`${classes.elem}`}
                          >
                            {ob.value}
                          </span>
                          </div>
                         
                        );
                      })}
                </div>
              </Popover>
            </div>
            </div>
           
            <div style={{ width: '100%' }}>
              <TextField
                label="How can we help you?"
                value={description}
                onChange={setDescription}
                multiline={5}
                autoComplete="off"
              />
            </div>
          
            <div style={{ width: '40%', 
                border: '1px solid grey',
                padding: '10px',
                borderRadius: '10px',
                background: 'white',
              }}>
              <input
              type='file'
              multiple
              onChange={(e) => {
                if(e.target.files){
                  if (e.target.files) {
                    const fileArray = Array.from(e.target.files);
                    setFiles(fileArray);
                  }
                }
              }}
              ></input>
            </div>
            <Button submit variant="primary" tone='success'>Submit</Button>
          </FormLayout>
        </Form>
      </div>
    </Frame>
  );
}