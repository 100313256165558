import React, { useState, useEffect } from 'react';
import '../forms/styles/ProductApp.css';
import axios from 'axios';
import { getAppUrl, getShop } from '../components/GlobalVariables';

interface ShopifyVariant{
    id : string;
    title : string;
}
interface Variant{
    variantId : string;
    variantName : string;
    color : string;
}
interface Product {
  productId: string;
  productName: string;
  storeName: string;
  makeup: string;
  variants : Variant[];
}

const ProductAppPage: React.FC = () => {
  const [ShopifyProducts, setShopifyProducts] = useState<Product[]>([]);
  const [LocalProductsIds, setLocalProductsIds] = useState<string[]>([]);
  const [isEditOverlayOpen, setEditOverlayOpen] = useState(false);
  const [EditingProduct, setEditingProduct] = useState<Product | null>(null);
  const app_server_url = getAppUrl();
  const shopName = getShop();

const fetchLocalProducts = async () => {
    let LocalProducts : Product[] = [];
    try {
        const response = await axios.get(
            `${app_server_url}/shopify-plugin/products/all?shop=${shopName}`,
            {
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                    "skip_zrok_interstitial":"69420",
                }
            }
        );
        LocalProducts = (response.data as any[]).map(item => {
            return {
                productId: item.productId, 
                productName: item.productName,
                storeName: item.storeName,
                makeup: item.makeup,
                variants : item.variants,
            } as Product;
            });
    } catch (error) {
        console.error('Failed to fetch local products:', error);
    }
    return LocalProducts?LocalProducts:[];
};
const fetchShopifyProducts = async ()=> {
    try {
        const response = await axios.get(
            `${app_server_url}/shopify-plugin/shopifyProducts?shop=${shopName}`,
            {
                headers: {
                "ngrok-skip-browser-warning": "69420",
                "skip_zrok_interstitial":"69420",
                }
            }
        );
        const LocalProducts = await fetchLocalProducts();
        setLocalProductsIds(LocalProducts.map(localProduct => localProduct.productId));
        const AllShopifyProducts = (response.data.products as any[]).map(item => {
            const LocalProduct = LocalProducts.find( localProduct => localProduct.productId === item.id.toString())
            return LocalProduct?LocalProduct:
            {
                productId : item.id.toString(),
                productName : item.title,
                storeName : shopName,
                makeup : 'lipstick',
                variants : item.variants.map((variant : ShopifyVariant) => {
                    return {
                        variantId : variant.id.toString(),
                        variantName : variant.title,
                        color : '#000000'
                    } as Variant;
                }),
            } as Product;
        });
        setShopifyProducts(AllShopifyProducts || []);
    } catch (error) {
        console.error('Failed to fetch shopify products:', error);
    }
}
useEffect(() => {
    fetchShopifyProducts();
  }, []);

const deleteProduct = async (product: Product) => {
    try {
        setLocalProductsIds(LocalProductsIds.filter(id => id != product.productId));
        const response = await axios.delete(`${app_server_url}/shopify-plugin/products/${product.productId}?shop=${shopName}`, {
            headers: {
                "ngrok-skip-browser-warning": "69420",
                "skip_zrok_interstitial":"69420",
            },
        });
    } catch (error) {
        console.error('Failed to delete product:', error);
    }
};

const saveProduct = async (product : Product) => {
    try {
        if(!LocalProductsIds.includes(product.productId))
            setLocalProductsIds(LocalProductsIds.concat(product.productId));
        setShopifyProducts(ShopifyProducts.map(shopifyProduct => {
            if(shopifyProduct.productId === product.productId)
                return product;
            return shopifyProduct;
        }));
        const response = await axios.post(`${app_server_url}/shopify-plugin/products?shop=${shopName}`, 
            product,
            {
                headers: {
                'Content-Type': 'application/json',
                "ngrok-skip-browser-warning": "69420",
                "skip_zrok_interstitial":"69420",
                }
            }
        );
        await cancelEdit();
    } catch (error) {
        console.error('Failed to save product edit:', error);
    }
};

const editOverlay = async (product: Product) => {
    setEditingProduct(product);
    setEditOverlayOpen(true);
};

const cancelEdit = async () => {
    setEditOverlayOpen(false);
    setEditingProduct(null);
}

    return (
    <div>
        <div id="products-list">
        {
            ShopifyProducts.length === 0? 
            (
                <p>No products selected.</p>
            ) : 
            (
                <>
                    {ShopifyProducts.map(product => (
                                    LocalProductsIds?.includes(product.productId))?
                                    (<div className="selected-product" key={product.productId}>
                                    <span>{product.productName} (Makeup: {product.makeup})</span>
                                    <button className="edit-button" onClick={() => editOverlay(product)}>Edit</button>
                                    <button className="delete-button" onClick={() => deleteProduct(product)}>Delete</button>
                                    </div>)
                                    :
                                    (<div className="selected-product" key={product.productId}>
                                    <span>{product.productName} (Makeup: {product.makeup})</span>
                                    <button className="add-button" onClick={() => saveProduct(product)}>Add</button>
                                    </div>)
                                    
                        
                    )}
                </>
            )
        }
        </div>

        {
            isEditOverlayOpen && EditingProduct && 
            <div className='overlay'>
                <div className='overlay-content'id="variant-list">
                {
                    EditingProduct.variants.length === 0 ? 
                    (
                        <p>No Variants found for this product.</p>
                    ) : 
                    (
                        <>
                            <label htmlFor="makeup-type">Select Makeup Type:</label>
                            <select id="makeup-type" value={EditingProduct.makeup} onChange={(e) => setEditingProduct(prev => prev ? {...prev, makeup: e.target.value} : prev)}>
                            <option value="lipstick">Lipstick</option>
                            <option value="blush">Blush</option>
                            <option value="eyeliner">Eyeliner</option>
                            <option value="eyeshadow">Eyeshadow</option>
                            {/* <option value="foundation">Foundation</option> */}
                            <option value="lipliner">Lipliner</option>
                            <option value="mascara">Mascara</option>
                            </select>
                            {   
                                EditingProduct.variants.map(EditingVariant => (  
                                    <div className="selected-product" key={EditingVariant.variantId}>
                                        <span>{EditingVariant.variantName}</span>
                                        <label htmlFor="color-picker">Choose Color:</label>
                                        <input type="color" id="color-picker" value={EditingVariant.color} onChange={(e) => {
                                                                                                                        const updatedVariants = EditingProduct.variants.map(variant => 
                                                                                                                            variant.variantId === EditingVariant.variantId ? {...variant, color: e.target.value} : variant
                                                                                                                        );
                                                                                                                        setEditingProduct(prev => prev ? {...prev, variants: updatedVariants} : prev);
                                                                                                                        }}/>
                                        <span>{EditingVariant.color}</span>
                                    </div>
                                    )
                                )   
                            }
                            <button onClick={() => saveProduct(EditingProduct)}>Save</button>
                            <button onClick={cancelEdit}>Cancel</button>
                        </>
                    )
                }
            </div>
            </div>

        }
    </div>
    );
};

export default ProductAppPage;
