import { LegacyCard, Tabs, Text } from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";
import Home from "./home";
import ProductIntegration from "./ProductIntegration";
import axios from "axios";
import {
  getApiKey,
  getApp,
  getAppUrl,
  getHost,
  getIsEmbedded,
  getIsFirstInstallation,
  getShop,
} from "../components/GlobalVariables";
import FAQ from "./faq";
import Subscription from "./Subscription";

import classes from "./try.module.scss";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import {
  addProduct,
  addSubscription,
  cancelSubscription,
  deleteProduct,
  fetchAllShopifyProducts,
  fetchSubscriptionData,
  getSelectedCategories,
  getSessions,
  handleTabChange,
  postSelectedCategories,
  saveProductInfo,
} from "../services/apis/pageIntegeration";
import { useSelector } from "react-redux";
import { selectProductState } from "../store/slices/productsSlice";
import Configuration from "../components/Onboarding/Configuration";
import Analytics from "../components/Analytics/Analytics";
import { getShopData } from "../utils/api";
import { ContactUs } from "./contactus";
import { logEvent } from "../utils/mixpanel";
import { AnalyticsIds } from "../utils/analyticsIds";
import { subscriptionDetails } from "../utils/constants";

export default function Dashboard() {
  const isFirstInstallation = getIsFirstInstallation();
  const {
    currentSubscription,
    currentSessions,
    disableTabs,
    showCancelConfirmPopup,
    selected,
  } = useSelector(selectProductState);

  useEffect(() => {
    getShopData()
  },[])

  const handleChangeTab = (id:number) => {
    handleTabChange(id)
    if(id === 1){
      logEvent('add_event', {
        key: AnalyticsIds.vtoProductIntegrationViewed,
      })
    }
    if(id === 3){
      logEvent('add_event', {
        key: AnalyticsIds.vtoAnalyticsViewed,
      })
    }
    if(id === 2){
      logEvent('add_event', {
        key: AnalyticsIds.vtoConfigurationViewed,
      })
    }
    if(id === 4){
      logEvent('add_event', {
        key: AnalyticsIds.vtoFaqViewed,
      })
    }
    if(id === 5){
      logEvent('add_event', {
        key: AnalyticsIds.vtoChoosePlanViewed,
      })
    }
  }

  const tabs = [
    {
      id: "home",
      content: "Home",
      panelID: "home",
      component: (
        <Home
          currentPlan={subscriptionDetails.find(
            (plan:any) => plan.key === currentSubscription
          )}
          goToProducts={() => handleChangeTab(1)}
          goToAnalytics={() => handleChangeTab(3)}
          goToContactUs={() => handleChangeTab(6)}
          goToPlans={() => handleChangeTab(5)}
          goToFaq={() => handleChangeTab(4)}
          goToConfiguration={() => handleChangeTab(2)}
          currentSessions={currentSessions}
        />
      ),
    },
    {
      id: "product",
      content: "Product Integration",
      panelID: "product",
      component: <ProductIntegration state="dashboard"/>
    },
    {
      id: "configuration",
      content: "VTO Configuration",
      panelID: "configuration",
      component: <Configuration/>
    },
    {
      id: "analytics",
      content: "Analytics",
      panelID: "analytics",
      component: <Analytics />,
    },
    {
      id: "faq",
      content: "FAQ",
      panelID: "faq",
      component: <FAQ />,
    },
    {
      id: "subscription",
      content: "Choose Plan",
      panelID: "subscription",
      component: (
        <Subscription
          isFirstInstallation={isFirstInstallation}
          goToContactUs={() => handleTabChange(5)}
          currentSubscription={currentSubscription}
          showCancelConfirmPopup={showCancelConfirmPopup}
          addSubscription={addSubscription}
          cancelSubscription={cancelSubscription}
          currentPlan={subscriptionDetails.find(
            (plan:any) => plan.key === currentSubscription
          )}
        />
      ),
    },
    {
      id: "contactus",
      content: "Contact Us",
      panelID: "contactus",
      component: <ContactUs/>
    }
  ];

  return (
    <Tabs
      tabs={tabs}
      selected={selected}
      onSelect={handleTabChange}
      fitted
      disabled={disableTabs}
    >
      <div className={classes.spacing}>
        <LegacyCard.Section
          title={tabs[selected].component}
        ></LegacyCard.Section>
      </div>
    </Tabs>
  );
}
