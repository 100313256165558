import React, { useCallback, useEffect, useState } from 'react'
import { Text } from "@shopify/polaris";
import classes from './Analytics.module.scss'
import { analyticsData, getAnalytics, ICardState } from '../../utils/analytics';
import { DonutChart, SimpleBarChart } from '@shopify/polaris-viz';
import '@shopify/polaris-viz/build/esm/styles.css';
import { IAnalyticsEventProps, IFetchData, pushAnalyticsRenderedData } from '../../store/slices/analyticsSlice';
import store from '../../store/store';

interface AnalyticsProps{
    heading: string
    children: React.ReactNode
    loading: boolean
    error: string
}

export interface IOperation {
    withIndex: number // index of data with which operation needs to be done
    operation: string // mathematical operation (contains ^ which will be replaced by value of withIndex index value)
}

export interface IAnalyticsData{
    id:number
    name: string
    filter: string
    operation?: IOperation
    graphType: string
    eventProps: IAnalyticsEventProps
}

const AnalyticsCard = ({heading,children,loading,error}:AnalyticsProps) => {
    return (
        <div className={`${classes.cardwrapper} ${loading && classes.loading}`}>
            {loading && <div className={classes.loader}>{'Loading...'}</div> }
            {error !== "" && <div className={classes.error}>{error}</div>}
            <div className={classes.header}><span>{heading}</span></div>
            <div className={classes.body}>{children}</div>
        </div>
    )
}

const Analytics = () => {
    const [analyticsState,setAnalyticsState] = useState<ICardState[]>([])
    useEffect(() => {
        const callBack = async() => {
            for(const x of analyticsData){
                for(const y of x){
                    await getAnalytics(y).then((res) => {
                        const curr = {
                            id: y.id,
                            data: res?.data,
                            error: res?.error,
                            loading: false
                        }
                        setAnalyticsState((prevState) => [...prevState, curr])
                        store.dispatch(pushAnalyticsRenderedData({id:y.id,name: y.name,data: res?.data}))
                    })
                }
            }
        }
        callBack()
       
    },[])

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Text as="h3" fontWeight="bold">
          Analytics
        </Text>
        {analyticsData.map((ob) => {
            return <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px'
            }}>
                {ob.map((obj) => {
                const currState = analyticsState.find((ob) => ob.id === obj.id)
                const children = () => {
                    if(obj.graphType === 'number'){
                        return <span>{currState?.data}</span>
                    } else if(obj.graphType === 'barchart'){
                        if(currState?.data){
                            return (
                                <div style={{
                                    height: '100%',
                                    width: '100%'
                                  }}>
                                <SimpleBarChart
                                data={currState?.data}
                              legendPosition="top"
                              /></div>
                            )
                        } 
                    } else if (obj.graphType === 'donutchart'){
                         if(currState?.data){
                            return (
                                <div style={{
                                    height: '100%',
                                    width: '100%'
                                  }}>
                                <DonutChart
                                comparisonMetric={{
                                  accessibilityLabel: 'trending up 6%',
                                  metric: '6%',
                                  trend: 'positive'
                                }}
                                data={currState?.data}
                                legendPosition="top"
                              /></div>
                            )
                        } 
                    }
                    return <></>
                }
                return(
                <AnalyticsCard heading={obj.name} loading={currState?.loading ?? true} error={currState?.error ?? ""}>
                    {children()}
                </AnalyticsCard>
                )
            })}
            </div>
           
     
        })}

      </div>
    );
  };

export default Analytics