import { useState, useMemo, useEffect } from "react";
import classes from "./Onboarding.module.scss";
import { categories, onboardingSteps, subscriptionDetails } from "../../utils/constants";
import ProductIntegration from "../../views/ProductIntegration";
import {
  getAppUrl,
  getIsFirstInstallation,
  getShop,
} from "../GlobalVariables";
import { useNavigate } from "react-router-dom";
import Welcome from "../Welcome/Welcome";
import { checkInstallations, fetchThemes } from "../../utils/api";
import store from "../../store/store";
import { useSelector } from "react-redux";
import {
  selectProductState,
  setSelected,
  setSelectedCategories,
  setShowOnboard,
} from "../../store/slices/productsSlice";
import {
  getSessions,
  getSelectedCategories,
  fetchAllShopifyProducts,
  fetchSubscriptionData,
  fetchLocalProducts,
  postSelectedCategories,
} from "../../services/apis/pageIntegeration";
import Congratulations from "../Congratulations/Congratulations";
import Configuration from "./Configuration";
import { logEvent } from "../../utils/mixpanel";
import { AnalyticsIds } from "../../utils/analyticsIds";

let installInterval: NodeJS.Timer = setInterval(() => {},1000)

function Onboarding() {
  const [activeStep, setActiveStep] = useState(1);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);
  const navigate = useNavigate();
  const app_server_url = getAppUrl();
  const isFirstInstallation = getIsFirstInstallation();
  const shopName = getShop();
  const {
    currentSubscription,
    pageCursor,
    searchVal,
    activeTheme,
    localproducts,
    blockNextToSavePrdts,
    showOnboard,
    installVto,
    installFab
  } = useSelector(selectProductState);
  const addedProductsData = JSON.parse(JSON.stringify(localproducts || []));

  const checkInstallationCondition = async() => {
    const y = await checkInstallations()
    return y
  }

  const checkLocalProducts = async() => {
      const prdts = await fetchLocalProducts()
      return prdts
  }

  useEffect(
  () => {
    const callback = async () => {
      let checkInstallation = false
      let checkLocalPrdt = false
      await checkInstallationCondition().then((resp)=>{
        if(resp?.appBlock || resp?.appEmbedBlock){
          checkInstallation = true
        }
      })
      await checkLocalProducts().then((ob) => {
        if(ob){
          checkLocalPrdt = true
        }
      })
      const currentPlan = subscriptionDetails.find(
        (plan) => plan.key === currentSubscription
      );
     if(!isFirstInstallation && currentPlan?.key === "free"){
        store.dispatch(setSelected(5));
        navigate("/new")
      }
      if(checkInstallation && checkLocalPrdt){
        navigate("/new")
        store.dispatch(setShowOnboard(false)) 
      } else {
        store.dispatch(setShowOnboard(true)) 
      }
    }

    callback()
  }, [currentSubscription])

  useEffect(() => {
    getSessions();
    getSelectedCategories();
  }, [app_server_url, shopName]);

  useEffect(() => {
    fetchAllShopifyProducts();
  }, [app_server_url, shopName, pageCursor, searchVal]);

  useEffect(() => {
    fetchSubscriptionData();
  }, [isFirstInstallation, shopName, app_server_url]);

  useEffect(() => {
    fetchThemes();
  }, []);

  useEffect(() => {
    let selectCats:any[] = []
    categories.forEach((ob) => {
      selectCats.push(ob.value)
    })
    store.dispatch(setSelectedCategories(selectCats));
    postSelectedCategories(selectCats)

  },[])

  const activeStepData = useMemo(() => {
    return onboardingSteps.find((ob) => ob.id === activeStep);
  }, [activeStep]);

  if(!showOnboard){
    return null
  }

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.formbody} ${!showWelcomeScreen && classes.further}`}>
        {!showWelcomeScreen && activeStep !==3 && (
          <div className={classes.top}>
             <div
                  className={`${classes.step}`}
                  // onClick={() => setActiveStep(step.id)}
                >
                  <span className={classes.stepname}>{`${activeStepData?.name}`}</span>
                  <span className={classes.stepcount}>{`${activeStep}/${onboardingSteps.length-1}`}</span>
              </div>
              <div className={classes.progress}>
                <span className={classes.fill} style={{
                  width: `${(activeStep/(onboardingSteps.length-1)) * 100}%`
                }}></span>
              </div>
          </div>
        )}
        {/* {!showWelcomeScreen && <Heading />} */}
        {!showWelcomeScreen && (
          <div className={classes.middle}>
            {activeStep === 1 && <Configuration installInterval={installInterval}/>}
            {activeStep === 2 && <ProductIntegration state="onboarding" />}
            {activeStep === 3 && (
            <Congratulations/>
            )}
          </div>
        )}
        {showWelcomeScreen && <Welcome setShowWelcomeScreen={setShowWelcomeScreen} />}
        {!showWelcomeScreen && activeStep !== onboardingSteps.length && <div
          className={`${classes.footer} ${
            showWelcomeScreen && classes.welcome
          }`}
        >
          { (
            <button
              className={`${classes.footerbtn} ${classes.backbtn} ${activeStep === 1 && classes.disable}`}
              onClick={() => {
                if (activeStep > 1) {
                  setActiveStep(activeStep - 1);
                }
              }}
            >
              Back
            </button>
          )}
          <button
             className={`${classes.footerbtn} ${activeStep === 1 && (installVto && activeTheme !== null) && classes.active} ${activeStep === 2 && addedProductsData.length > 0 && !blockNextToSavePrdts && classes.active }`}
            onClick={() => {
              if (showWelcomeScreen) {
                setShowWelcomeScreen(false);
              } else {
                if (activeStep <= onboardingSteps.length - 1) {
                  if(activeStep === 1){
                    if((installVto || installFab) && activeTheme !== null){
                      clearInterval(installInterval)
                      setActiveStep(activeStep + 1);
                      logEvent('add_event', {
                        key: AnalyticsIds.stage1Completed,
                        segmentation:{
                          themeName: activeTheme?.name || ''
                        }
                      })
                    }
                  }
                  else if (activeStep === 2){
                    if(addedProductsData.length > 0){
                      setActiveStep(activeStep + 1); 
                      logEvent('add_event', {
                        key: AnalyticsIds.stage2Completed,
                        segmentation:{
                          themeName: activeTheme?.name || ''
                        }
                      })
                    }
                  } 
                  else {
                    setActiveStep(activeStep + 1);
                  }
      
                }
                // if (activeStep === onboardingSteps.length) {
                //   navigate("/new");
                // }
              }
            }}
          >
            {activeStep === onboardingSteps.length-1 ? "Finish" : "Next"}
          </button>
        </div>}
      </div>
    </div>
  );
}

export default Onboarding;
