import React from "react";
import classes from "./Welcome.module.scss";
import { logEvent } from "../../utils/mixpanel";
import { AnalyticsIds } from "../../utils/analyticsIds";

interface Inprops{
  setShowWelcomeScreen: (id:boolean) => void
}

const Welcome = ({setShowWelcomeScreen}:Inprops) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <div className={classes.left}>
          <span className={classes.head}>
          Hey there, welcome to Metadome Virtual Makeup Try On!
          </span>
          <span className={classes.desc}>
          Let’s setup your Virtual Try On (VTO) experience. Follow the steps on the next screens to install, setup, and customize your VTO experience.
          </span>
          <div className={classes.bottom}>
            <button onClick={() => {
              setShowWelcomeScreen(false)
              logEvent('add_event', {
                key: AnalyticsIds.getStartedCliked
              })
            }}>Get Started</button>
            <span onClick={() => {
              window.open('https://products.metadome.ai/vto-shopify/VTOOnboardingDocumentation.pdf')
            }}>Read documentation</span>
          </div>
        </div>
        <div className={classes.right}>
          {/* <video src={`/assets/sunroof_en_audi.mp4`} autoPlay /> */}
          <img src="./assets/welcomeimg.png"/>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
