import React, { useCallback, useEffect, useState } from 'react'
import classes from './Configurator.module.scss'
import { useSelector } from 'react-redux';
import { selectProductState, setActiveTheme, setInstallFab, setInstallVto } from '../../store/slices/productsSlice';
import { ITheme } from '../../interfaces/globals';
import store from '../../store/store';
import { checkInstallations } from '../../utils/api';
import { getAppUrl, getIsFirstInstallation, getShop, getAppExtensionId } from '../GlobalVariables';
import { Popover } from "@shopify/polaris";
import { logEvent } from '../../utils/mixpanel';
import { AnalyticsIds } from '../../utils/analyticsIds';

interface Inprops{
    installInterval?: NodeJS.Timer
}

function Configuration({installInterval}:Inprops) {
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const [selectedTab,setSelectedTab] = useState("1")
    const [hoverTheme,setHoverTheme] = useState(0)
    const [chooseTheme,setChooseTheme] = useState(false)  
  const {
    themes,
    activeTheme,
    installVto,
    installFab,
    selected
  } = useSelector(selectProductState);

  const getSortedThemes = () => {
    const x = themes.filter((ob) => ob.role === "MAIN")
    const rem = themes.filter((ob) => ob.role !== "MAIN")
    return [...x,...rem]
  }

  const getImagebyTab = () => {
    if(selectedTab === "1"){
      return "ChooseTheTheme-Shopify-Video.mp4"
    }
    if(selectedTab === "2"){
      return "VTO-Shopify-Video.mp4"
    }
    if(selectedTab === "3"){
      return "FAB-Shopify-Video.mp4"
    }
  }

  useEffect(() => {
    if(installVto){
      logEvent('add_event', {
        key: AnalyticsIds.vtoButtonInstalled,
        segmentation:{
          themeName: activeTheme?.name || ''
        }
      })
    }
    if(installFab){
      logEvent('add_event', {
        key: AnalyticsIds.vtoFabInstalled,
        segmentation:{
          themeName: activeTheme?.name || ''
        }
      })
    }

  },[installVto,installFab])

  useEffect(() => {
    if(installInterval){
        clearInterval(installInterval)
    }
  },[installInterval, selected])

    const activator = (
      <button onClick={toggleActive} className={`${classes.dropdownbtn} ${active && classes.active}`}>
        <span>{activeTheme === null
          ? "Choose a Theme"
          : `${activeTheme.name}`}</span>
        <img src="./assets/icons/downarrow.svg"/>  
      </button>
    );
  
  return (
    <div className={classes.middle}>
        <div className={classes.left}>
                <div className={`${classes.first} ${selectedTab === "1" && classes.active}`} id="1" onClick={() => setSelectedTab("1")}>
                <div className={classes.top}>
                    <span className={classes.head}>
                    Choose your store’s theme from the drop down. 
                    </span>
                    <span className={classes.desc}>
                    Watch the video on the right to see how to find your store’s theme. 
                    </span>
                  </div>
                  <Popover
                    active={active}
                    activator={activator}
                    onClose={toggleActive}
                    sectioned
                    fullWidth
                  >
                    <div
                      className={classes.elems}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: '200px',
                        overflow: 'scroll',
                      }}
                    >
     
                      {getSortedThemes().map((ob: ITheme) => {
                        return (
                          <div className={classes.elewrapper} style={{
                            width:'100%',
                            padding:'10px 20px',
                            background: ob?.id === hoverTheme ? 'rgba(247, 247, 247, 1)': '',
                            cursor: "pointer"
                          }} onMouseEnter={() => {
                            setHoverTheme(ob?.id)
                          }}
                          onClick={() => {
                            store.dispatch(setActiveTheme(ob))
                            toggleActive()
                            setChooseTheme(true)
                            logEvent('add_event', {
                              key: AnalyticsIds.themeSelected,
                              segmentation:{
                                themeName: ob.name
                              }
                            })
                            if(installInterval){
                                clearInterval(installInterval)
                            }
                            checkInstallations(ob?.gid).then((resp) => {
                              store.dispatch(setInstallVto(resp?.appBlock ?? false))
                              store.dispatch(setInstallFab(resp?.appEmbedBlock ?? false))
                            })
                            }}>
                            {/* <img src="./assets/icons/tick.svg"/> */}
                             <span
                             style={{
                              fontWeight: ob?.role === "MAIN" ? '600': "initial"
                             }}
                            className={`${classes.elem} ${ob?.role === "MAIN" && classes.mainelem}`}
                          >
                            {ob?.name}{ob?.role === "MAIN" ? "(Active)" : ""}
                          </span>
                          </div>
                         
                        );
                      })}
                    </div>
                  </Popover>
                </div>
                <div className={`${classes.first} ${(selectedTab === "2" || installVto) && classes.active}`}id="2" onClick={() => setSelectedTab("2")} style={{
                  opacity: !chooseTheme ? '0.4' : '1',
                  pointerEvents: !chooseTheme ? 'none' : "all",
                  cursor: !chooseTheme ? 'default' : 'pointer'
                }}>
                <div className={classes.top}>
                    <span className={classes.head}>
                      {installVto && <img src="./assets/icons/tick.svg"/>}
                      <span>Install the Metadome VTO</span>
                    </span>
                    <span className={classes.desc}>
                    Install our plugin. You will be redirected to your store. Click ’save’ to enable VTO and come back here for the next steps.
                    </span>
                  </div>
                  <button className={`${classes.vtobtn} ${installVto && classes.disable}`}
                    onClick={() => {
                      if(installVto){
                        return
                      }
                      window.open(
                        `https://${getShop()}/admin/themes/${
                          activeTheme === null ? "current" : activeTheme?.id
                        }/editor?template=product&addAppBlockId=${getAppExtensionId()}/VTOAppBlock&target=mainSection`
                      );
                      if(installInterval){
                        clearInterval(installInterval)
                      }
                      installInterval =  setInterval(() => {
                        checkInstallations(activeTheme?.gid).then((resp) => {
                            store.dispatch(setInstallVto(resp?.appBlock ?? false))
                            store.dispatch(setInstallFab(resp?.appEmbedBlock ?? false))
                           if(resp?.appBlock && resp?.appEmbedBlock){
                            clearInterval(installInterval)
                          }
                        })
                      },1000)
                    }}
                  >
                    Install VTO
                  </button>
                </div>
                <div className={`${classes.first} ${(selectedTab === "3" || installFab) && classes.active}`}id="3" onClick={() => setSelectedTab("3")} style={{
                  opacity: !chooseTheme ? '0.4' : '1',
                  pointerEvents: !chooseTheme ? 'none' : "all",
                  cursor: !chooseTheme ? 'default' : 'pointer'
                }}>
                  <div className={classes.top}>
                    <span className={classes.head}>
                      {installFab && <img src="./assets/icons/tick.svg"/>}
                      <span>Add a Floating Action Button (FAB) on the Product Page</span>
                    </span>
                    <span className={classes.desc}>
                    Install VTO FAB. You will be redirected to your store. Click ’save’ to enable the button and come back here for the next steps.
                    </span>
                  </div>
                 
                  <button  className={`${classes.vtobtn} ${installFab && classes.disable}`}
                    onClick={() => {
                      if(installFab){
                        return
                      }
                      window.open(
                        `https://${getShop()}/admin/themes/${
                          activeTheme === null ? "current" : activeTheme?.id
                        }/editor?context=apps&template=product&activateAppId=${getAppExtensionId()}/VTOAppEmbedBlock`
                      );
                      if(installInterval){
                        clearInterval(installInterval)
                      }
                      installInterval =  setInterval(() => {
                        checkInstallations(activeTheme?.gid).then((resp) => {
                            store.dispatch(setInstallVto(resp?.appBlock ?? false))
                            store.dispatch(setInstallFab(resp?.appEmbedBlock ?? false))
                          if(resp?.appBlock && resp?.appEmbedBlock){
                            clearInterval(installInterval)
                          }
                        })
                      },1000)
                    }}
                  >
                    Install VTO FAB
                  </button>
                </div>
        </div>
        <div className={classes.right}>
                <video src={`./assets/${getImagebyTab()}`} autoPlay loop/>
                {/* <img src={`./assets/${getImagebyTab()}`}/> */}
        </div>
    </div>
  )
}

export default Configuration