export const onboardingSteps = [
  {
    id: 1,
    name: "Setup and Configure VTO",
    heading: "Configure VTO Button",
  },
  {
    id: 2,
    name: "Add products to enable the try on",
    heading: "",
  },
  {
    id: 3,
    name: "",
    heading: "",
  }
];

export const categories = [
  { label: "Lipstick", value: "Lipstick" },
  { label: "Lipliner", value: "Lipliner" },
  { label: "Eyeshadow", value: "Eyeshadow" },
  { label: "Eyeliner", value: "Eyeliner" },
  { label: "Blush", value: "Blush" },
]

export const segeregatedCategories = {
  eyes: ['eyeliner', 'eyeshadow', 'mascara'],
  lips: ['lipstick', 'lipliner'],
  face: ['blush'],
}

export const appBlockId = `shopify://apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/blocks/${process.env.REACT_APP_SHOPIFY_APP_EXTENSION_APP_BLOCK_NAME}/${process.env.REACT_APP_SHOPIFY_APP_EXTENSION_ID}`

export const appEmbedBlockId = `shopify://apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/blocks/${process.env.REACT_APP_SHOPIFY_APP_EXTENSION_APP_EMBED_BLOCK_NAME}/${process.env.REACT_APP_SHOPIFY_APP_EXTENSION_ID}`

export const allFaqs = [
  {
      question: 'How does Metadome.ai’s Virtual Try-On Work?',
      answer: 'Metadome.ai Virtual Try-On runs on a base that is enabled by JavaScript API. The app reduces guesswork in color and shade matching for makeup and foundation, allowing customers to make decisions faster.',
      type: 'default',
      open: true
  },
  {
      question: 'How can I renew my subscription?',
      answer: 'To ensure uninterrupted access to makeup try-on for your customers, your subscription automatically renews either monthly or yearly.If you like to change your subscription plan, please proceed to “Choose Plan” page.',
      type: 'default',
      open: false
  },
  {
      question: 'What does your analytics cover?',
      answer: 'Your metrics can be accessed from a Mixpanel dashboard. The link to your Mixpanel dashboard will be shared with you shortly after registration. The base plan covers 4 key metrics including, DAU, MAU, Clicks, and Users engagement times. The pro plan covers everything in the base plan along with product click metrics, and the ability to customize your dashboard.',
      type: 'default',
      open: false
  },
  {
      question: 'New Features coming soon!',
      answer: 'We have exciting new features coming soon! Our foundation finder makes it super easy for your customers to find their perfect shade.  Boost your average order value with preset looks that create automated looks with a bundle of your products. We’re bringing exciting GenAI capabilities too!',
      type: 'default',
      open: false
  },
  {
      question: 'How to integrate the VTO button into your website',
      answer: `After installing the app and selecting products, customize the theme template. Navigate to the Products page, click Add block, switch to the Apps section, and select Metadome's Virtual Try-On embedded app block. Then, locate the Virtual Try-On CTA on the product page and position it as desired.Remember, Virtual try-on button will only be visible on the selected and saved products.`,
      type: 'default',
      open: false
  },
  {
      question: ' Step-By-Step guide',
      answers: [
          `Install the App: After installation, you'll automatically enter the free plan. Go to Product Integration tab`,
          'Add Products: Select the products you want to enable for virtual try-on. Choose the relevant category for each product.',
          'Select Categories: Choose category for each selected product',
          `Enter Hex Codes: Input the product's hex code, ensuring it starts with a # symbol.`,
          `Save Changes: Click Save Products after each update to apply the changes to the try-on link on product description page`,
      ],
      type: 'list',
      open: false
  }
]

export const subscriptionDetails = [
  {
    key: "free",
    name: "Free",
    price: 0,
    description: [
      "Photo/Live Try-On",
      "Upto 1k user sessions",
      "Upto 50 active products",
      "All usage analytics",
    ],
    totalSessions: "1000",
    maxCategories: 3,
  },
  {
    key: "base",
    name: "Base",
    price: 99,
    description: [
      "14-day free trial",
      "Photo/Live Try-On",
      "Upto 50k user sessions",
      "Unlimited active products",
      "All usage analytics",
    ],
    totalSessions: "50k",
    maxCategories: 3,
  },
  {
    key: "pro",
    name: "Pro",
    price: 199,
    description: [
      "14-day free trial",
      "Photo/Live Try-On",
      "Upto 200k user sessions",
      "Unlimited active products",
      "All usage analytics",
    ],
    totalSessions: "200k",
    maxCategories: 5,
  },
];

export const contactUsFormDropDownData = [
  { label: "Installation Related Issue", value: "Installation Related Issue" },
  { label: "Virtual-Try-on button customization related issues", value: "Virtual-Try-on button customization related issues" },
  { label: "Unable to add products/ customize products for VTO", value: "Unable to add products/ customize products for VTO" },
  { label: "VTO output quality related issues", value: "VTO output quality related issues" },
  { label: "Billing and Payment related issues", value: "Billing and Payment related issues" },
  { label: "Upgrading to Pro Plan", value: "Upgrading to Pro Plan" },
  { label: "Other", value: "Other" },
]
